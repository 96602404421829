<template>
    <div class="route-wrapper">

        <div
            v-if="todayFlights && todayFlights.length > 0"
            class="current-flight"
        >
            <flight-info
                v-for="flight in todayFlights"
                :key="flight.id"
                :flight="flight"
            />
        </div>
        <div v-else class="upload-title">{{ $t('myFlights.title') }}</div>
        <div
            :style="{ '--base-gradient-color': theming.boardingGradientBaseColor }"
            class="ticket-image-wrapper"
        >
            <img :src="theming.boardingPassImagePointsUrl" alt="ticketImage">
            <b-button
                :style="{backgroundColor: theming.boardingButtonColor, color: theming.boardingButtonTextColor}"
                class="scan-button"
                @click="showConsentModal"
            >
                {{ $t('myFlights.scanBoardingButton') }}
            </b-button>
        </div>
        <span class="upcoming-flights">{{ $t('myFlights.upcomingFlights') }}</span>
        <div v-if="upcomingFlights && upcomingFlights.length > 0" class="upcoming-container">
            <flight-info
                v-for="(boardingPass, index) in upcomingFlights"
                :key="index"
                :flight="boardingPass"
                :is-lite="true"
            />
        </div>
        <div
            v-else
            class="boarding-flight-info-container"
        >
            <span class="no-data">{{ $t('myFlights.noUpcomingFlights[0]') }}</span>
            <span class="no-data">{{ $t('myFlights.noUpcomingFlights[1]') }}</span>
        </div>

    <!--        <b-button @click="history">{{ $t('myFlights.historyButton') }}</b-button>-->

    </div>
</template>

<script>
    import taskUtil from '@/utils/task-util'
    import emitter from '@/utils/emitter'
    import FlightInfo from '@/components/FlightInfo'
    import boardingPassService from '@/services/boarding-pass-service'
    import boardingPassUtils from '@/utils/boarding-pass-utils'

    export default {
        name: 'TaskScanBoardingPass',
        components: { FlightInfo },
        props: {
            taskIndex: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                file: null,
                isScanEnabled: false,
                boardingPassContent: null
            }
        },
        computed: {
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            mission() {
                return this.$store.getters.getMission(
                    parseInt(this.$route.params.missionId)
                )
            },
            theming() {
                return this.$store.getters.getTheming
            },
            currentUser() {
                return this.$store.getters.getCurrentUser
            },
            hasGivenConsent() {
                return this.$store.getters.getCurrentUser.boardingPassDataConsentGiven
            },
            todayFlights() {
                return this.$store.getters.todayFlights
            },
            upcomingFlights() {
                return this.$store.getters.upcomingFlights
            }

        },
        watch: {
            currentUser: {
                handler() {
                    this.getCurrentStatusWithId()
                },
                deep: true
            }
        },
        beforeDestroy() {
            emitter.off('statusChange')
        },
        async created() {
            emitter.on('statusChange', () => {
                this.getCurrentStatusWithId()
            })

            let missionId = parseInt(this.$route?.params?.missionId)
            let taskId = parseInt(this.$route?.params?.taskId)

            if (this.task && this.task.id) {
                let points = this.task.points

                const isInvalid = await this.$store.dispatch('startTask', { missionId, taskId })
                if (isInvalid) {
                    this.$router.push({ path: '/missions/' + missionId, replace: true })
                } else {
                    await taskUtil.updateTask(this.mission, this.task, points, true)
                }
            }
        },
        methods: {
            showConsentModal() {
                if (!this.hasGivenConsent) {
                    emitter.emit('openBoardingPassConsentModal')
                } else {
                    this.$router.push('/boarding-pass')
                }
            },
            async getCurrentStatusWithId() {
                const getBoardingPassesIds = this.currentUser?.boardingPasses?.map(flight => flight.id) ?? []

                if (getBoardingPassesIds.length === 0) {
                    return
                }

                const updatedStatus = await boardingPassService.getFlightStatus(getBoardingPassesIds)

                boardingPassUtils.updateBoardingPassesFlights(updatedStatus.data)
            }

        }
    }
</script>

<style scoped>
.route-wrapper {
    padding: 1rem 0;
    max-width: 350px;
    width: 90%;
    margin: 0 auto;
    font-weight: bold;
}

.upload-title {
    font-size: 1.25rem;
    color: #fff;
}

.ticket-image-wrapper {
    background: radial-gradient(94.31% 73.53% at 50% 54.36%, rgb(0 0 0 / 10%) 0%, var(--base-gradient-color) 100%);
    border-radius: 1rem;
    width: 100%;
    max-width: 350px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.1rem;
    margin: 2rem auto;
}

.ticket-image-wrapper img {
    object-fit: contain;
    width: 120px;
    height: auto;
}

.scan-button {
    border: none;
    height: 34px;
    max-width: 260px;
    width: 100%;
    display: grid;
    place-items: center;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 6px;
}

.boarding-flight-info-container {
    border-radius: 0.9375rem;
    background-color: rgb(0 0 0 / 50%);
    padding: 1rem 0.5rem;
    text-align: center;
    margin: 1rem auto;
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;
    justify-content: center;
}

.upcoming-container,
.current-flight {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.upcoming-container {
    margin-top: 1rem;
}

.no-data {
    color: var(--disabled-color);
}

.flights-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    margin: 0 auto;
}

.upcoming-flights {
    margin-top: 2rem;
    font-size: 1.25rem;
    color: var(--main-content-text);
    font-weight: 400;
}

@media screen and (min-width: 992px) {
    .route-wrapper {
        padding: 3rem 0;
        max-width: 945px;
    }

    .upload-title {
        font-size: 2rem;
    }

    .ticket-image-wrapper {
        max-width: 550px;
    }

    .ticket-image-wrapper img {
        width: 160px;
    }

    .scan-button {
        height: 44px;
    }

    .boarding-flight-info-container {
        border-radius: 15px;
        background-color: rgb(0 0 0 / 50%);
        padding: 1.5rem 1rem;
        text-align: center;
        margin: 0 auto;
        margin-top: 1rem;
        max-width: 945px;
        min-height: 160px;
    }

    .no-data {
        display: block;
    }
}

</style>
